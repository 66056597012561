import React, { useState } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import useWindowDimensions from "../../helpers/useWindowDimensions"

import logo from "../../images/icons/logo.svg"
import hamburger from "../../images/icons/hamburger.svg"

import NavigationModal from "../navigationModal/navigationModal"

import "./header.scss"

const Header = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const { width } = useWindowDimensions()

  const handleModalVisibility = () => {
    setModalVisible(prevState => !prevState)
  }

  const navigationItems = [
    {
      label: 'MERE marketi',
      scrollTo: '.about-wrapper'
    },
    {
      label: 'Galerija',
      scrollTo: '.gallery-wrapper'
    },
    {
      label: 'Zahtevi',
      scrollTo: '.requirements-wrapper'
    },
    {
      label: 'Lokacije',
      scrollTo: '.map-wrapper'
    },
    {
      label: 'Kontakt',
      scrollTo: '.contact-wrapper'
    }
  ]
  return (
    <>
      <header>
        <div className="navigation-container">
          <div className="logo-container">
            <img 
              src={logo} 
              alt="Placeholder Logo" 
              draggable="false" 
            />
          </div>
          {
            navigationItems.map(item => (
              <div 
                key={item.label}
                className="nav-item-container"
                
              >
                <span
                  href="#" 
                  onClick={() => scrollTo(item.scrollTo)}
                >
                  {item.label}
                </span>
              </div>
            ))
          }
          <div 
            className="hamburger-container"
            onClick={() => handleModalVisibility()}
          >
            <img
              src={hamburger}
              alt="Hamburger Icon"
              draggable="false"
            />
          </div>
        </div>
      </header>
      <NavigationModal
        modalVisible={modalVisible}
        navigationItems={navigationItems}
        scrollTo={(scrollLocation) => scrollTo(scrollLocation)}
        handleModalVisibility={() => handleModalVisibility()}
        width={width}
      />
    </>
  )
}

export default Header