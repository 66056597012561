import React from "react"

import "./sectionFooter.scss"

const Footer = () => {

  const getCurrentYear = () => {
    let date = new Date()
    return date.getFullYear()
  }
    

  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="company-info">
          <p>ROS PRODUKT SERVIS DOO</p>
          <p>Cara Nikolaja II 49/3, 11000 Beograd</p>
          <p>Matični broj: 21546097</p>
          <p>PIB: 111819037</p>
        </div>
        <div className="spacer" />
        <div className="site-info">
          <div className="copyright">
            <p><a href="mailto:razvoj@meremarketi.rs">razvoj@meremerketi.rs</a></p>
          </div>
          <div className="copyright">
            <p>Copyright © { getCurrentYear() } Mere Marketi</p>
          </div>
          <div className="made-with-heart">
            <p>Made with</p>
            <div className="heart-shape"/>
            <p>in Novi Sad</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer