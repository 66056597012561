import React from "react"

import "./navigationModal.scss"

const NavigationModal = ({ 
  modalVisible, 
  navigationItems, 
  scrollTo,
  handleModalVisibility,
  width
}) => {
  return (
    modalVisible && width < 512 
    ? (
      <div className={`navigation-modal-wrapper ${modalVisible && 'flip-animation'}`}>
        <div className="navigation-modal-container">
          <ul>
            {
              navigationItems.map(item => (
                <li
                  key={ item.label }
                >
                  <div 
                    className="nav-item-modal"
                    onClick={() => {
                      scrollTo(item.scrollTo)
                      handleModalVisibility()
                    }}
                  >
                    <span>{ item.label }</span>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    ) : (
      <div />
    )
  )
}

export default NavigationModal