import React from "react"

import "./layout.scss"

import Header from "../header/header"
import Footer from "../sectionFooter/sectionFooter"

const Layout = ({ children }) => {
  return (
    <div className="outer-wrapper">
      <Header />
      <div className="content">
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout